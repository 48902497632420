// GenericCheckout.jsx
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useStripeContext } from "../context/StripeContext";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../services/firebase/firebaseInit";

// Sponsorship package details array (copied from Sponsorships.jsx)
const sponsorshipPackages = [
  {
    id: "rookie",
    name: "Rookie Level",
    price: "$1,000",
    benefits: [
      "3 scholarships for underprivileged youth",
      "Logo/name on camp website with link",
      "Recognition in all email communications",
      "Social media shoutout (2 dedicated posts)",
      "Small logo on camp t-shirts",
    ],
  },
  {
    id: "allstar",
    name: "All-Star Level",
    price: "$2,500",
    popular: true,
    benefits: [
      "7 scholarships for underprivileged youth",
      "Medium logo on camp jerseys",
      "Booth space during family day",
      "Branded items in camper welcome packs",
      "Banner display during camp sessions",
      "Featured in camp highlight video",
      "All benefits from Rookie level",
    ],
  },
  {
    id: "mvp",
    name: "MVP Level",
    price: "$5,000",
    benefits: [
      "12 scholarships for underprivileged youth",
      "Primary logo placement on jerseys and banners",
      "News publication and TV promotion mentions",
      "Named sponsorship of one camp activity",
      "Speaking opportunity at opening ceremony",
      "First right of refusal for next year's sponsorship",
      "Professional photos with camp participants",
      "All benefits from All-Star level",
    ],
  },
  {
    id: "championship",
    name: "Championship Level",
    price: "$10,000",
    benefits: [
      "20 scholarships for underprivileged youth",
      '"Presented by [Sponsor Name]" on all materials',
      "Exclusive naming rights for camp or main court",
      "VIP access to all camp events",
      "Custom promotional video featuring your brand",
      "All benefits from MVP level",
    ],
  },
];

const CheckoutForm = ({ paymentInfo, setPaymentStatus, donationId }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);

    if (!stripe || !elements || !paymentInfo.clientSecret) {
      setError("Payment system not fully loaded. Please try again.");
      setProcessing(false);
      return;
    }

    const result = await stripe.confirmCardPayment(paymentInfo.clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          address: {
            line1: formData.address,
            city: formData.city,
            state: formData.state,
            postal_code: formData.zipCode,
          },
        },
      },
    });

    if (result.error) {
      console.error("Payment error:", result.error);
      setError(result.error.message);
      setProcessing(false);
    } else if (result.paymentIntent.status === "succeeded") {
      setSucceeded(true);
      setProcessing(false);
      setPaymentStatus("success");

      // Save customer info to the donation document
      try {
        const donationRef = doc(db, "donations", donationId);
        await updateDoc(donationRef, {
          customerInfo: formData,
        });
        console.log("Customer info saved successfully");
      } catch (error) {
        console.error("Error updating donation with customer info:", error);
        // Continue with navigation even if this fails
      }

      const transactionData = {
        ...paymentInfo,
        customerInfo: formData,
        date: new Date().toISOString(),
        status: "success", // This will be updated by the webhook
        transactionId: result.paymentIntent.id,
      };

      // Navigate to confirmation page immediately
      navigate("/payment-confirmation", {
        state: { paymentInfo: transactionData },
      });
    }
  };

  const cardElementOptions = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <form onSubmit={handleSubmit} className="mt-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Name*
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full p-3 border rounded"
            required
          />
        </div>

        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Email*
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full p-3 border rounded"
            required
          />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Phone Number
          </label>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="w-full p-3 border rounded"
          />
        </div>

        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Company/Organization
          </label>
          <input
            type="text"
            name="company"
            value={formData.company}
            onChange={handleChange}
            className="w-full p-3 border rounded"
          />
        </div>
      </div>

      {/* Address fields - show only for sponsorships or larger donations */}
      {(paymentInfo.type === "sponsorship" || paymentInfo.amount >= 500) && (
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Address
          </label>
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleChange}
            className="w-full p-3 border rounded mb-2"
            placeholder="Street Address"
          />
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
            <input
              type="text"
              name="city"
              value={formData.city}
              onChange={handleChange}
              className="w-full p-3 border rounded col-span-2"
              placeholder="City"
            />
            <input
              type="text"
              name="state"
              value={formData.state}
              onChange={handleChange}
              className="w-full p-3 border rounded"
              placeholder="State"
            />
            <input
              type="text"
              name="zipCode"
              value={formData.zipCode}
              onChange={handleChange}
              className="w-full p-3 border rounded"
              placeholder="Zip Code"
            />
          </div>
        </div>
      )}

      {paymentInfo.type === "donation" && (
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Message (Optional)
          </label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="w-full p-3 border rounded h-24"
            placeholder="Share why you're supporting our camp (optional)"
          />
        </div>
      )}

      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Card Details*
        </label>
        <div className="p-3 border rounded shadow-sm bg-white">
          <CardElement options={cardElementOptions} />
        </div>
      </div>

      {error && (
        <div className="mb-4 p-3 bg-red-100 text-red-700 rounded">{error}</div>
      )}

      <button
        type="submit"
        disabled={processing || !stripe || !paymentInfo.clientSecret}
        className={`w-full ${
          processing || !stripe || !paymentInfo.clientSecret
            ? "bg-gray-400"
            : "bg-orange-500 hover:bg-orange-600"
        } text-white font-bold py-3 px-6 rounded focus:outline-none focus:shadow-outline`}
      >
        {processing ? "Processing..." : `Pay $${paymentInfo.amount}`}
      </button>
    </form>
  );
};

// Component to display sponsorship details
const SponsorshipDetails = ({ packageId }) => {
  const sponsorshipPackage = sponsorshipPackages.find(
    (pkg) => pkg.id === packageId
  );

  if (!sponsorshipPackage) return null;

  return (
    <div className="mb-6 border border-gray-200 rounded-lg overflow-hidden">
      <div
        className={`py-3 px-4 ${
          packageId === "championship"
            ? "bg-yellow-100"
            : packageId === "mvp"
            ? "bg-purple-100"
            : packageId === "allstar"
            ? "bg-green-100"
            : "bg-blue-100"
        }`}
      >
        <h3 className="font-bold text-lg">{sponsorshipPackage.name} Package</h3>
      </div>
      <div className="p-4">
        <div className="mb-4">
          <div className="text-sm text-gray-600 mb-1">Package includes:</div>
          <ul className="space-y-2">
            {sponsorshipPackage.benefits.map((benefit, idx) => (
              <li key={idx} className="flex items-start">
                <svg
                  className="h-5 w-5 text-green-500 mr-2 mt-0.5 shrink-0"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
                <span className="text-sm">{benefit}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const GenericCheckout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [paymentStatus, setPaymentStatus] = useState("pending");
  const [donation, setDonation] = useState(null); // State to hold donation data
  const stripe = useStripeContext(); // Use the hook to access Stripe
  const [clientSecret, setClientSecret] = useState(null);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    const { donationId, amount, type } = location.state || {};

    if (!donationId || !amount || !type) {
      navigate("/sponsorships");
      return;
    }

    const fetchDonation = async () => {
      setProcessing(true);
      try {
        const docRef = doc(db, "donations", donationId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setDonation({ id: docSnap.id, ...docSnap.data() });
        } else {
          console.log("No such document!");
          setError("Donation not found. Please try again.");
          navigate("/sponsorships");
          return;
        }
      } catch (error) {
        console.error("Error fetching donation:", error);
        setError("Failed to retrieve donation. Please try again later.");
        navigate("/sponsorships");
        return;
      } finally {
        setProcessing(false);
      }
    };

    fetchDonation();
  }, [location.state, navigate]);

  useEffect(() => {
    if (!donation) return;

    const createPaymentIntent = async () => {
      setProcessing(true);
      try {
        const payload = {
          amount: donation.amount,
          type: donation.type || "donation",
          donationId: donation.id,

          metadata: {
            donation_id: donation.id, // Use the donation ID
            purpose: donation.metadata?.purpose || "Donation",
          },
        };

        // ADDED LOGGING HERE
        console.log(
          "Sending payload to createDonationPayment:",
          JSON.stringify(payload)
        ); // Log the payload

        const response = await fetch(
          "https://us-central1-ccfirebasetest.cloudfunctions.net/createDonationPayment",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );

        if (!response.ok) {
          // Log the response status and text for better error diagnosis
          console.error(
            "Error response from createDonationPayment:",
            response.status,
            await response.text()
          );
          throw new Error(`HTTP error: ${response.status}`);
        }

        const data = await response.json();
        setClientSecret(data.paymentIntent);
        console.log("Payment intent created successfully:", data);
      } catch (error) {
        console.error("Error creating payment intent:", error);
        setError("Failed to initialize payment. Please try again later.");
      } finally {
        setProcessing(false);
      }
    };

    createPaymentIntent();
  }, [donation, navigate]);

  if (!donation) {
    return (
      <div className="min-h-screen bg-gray-50 py-12">
        <div className="container mx-auto px-4 text-center">
          <div className="animate-pulse">
            {processing ? "Loading Donation Information..." : "Loading..."}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="container mx-auto px-4">
        <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-md overflow-hidden">
          <div className="bg-gradient-to-r from-orange-500 to-red-600 px-6 py-4">
            <h1 className="text-2xl font-bold text-white">
              {donation.type === "sponsorship"
                ? `${donation.metadata?.packageName} Sponsorship`
                : "Donation"}
            </h1>
          </div>

          <div className="p-8">
            <div className="mb-8">
              <h2 className="text-2xl font-bold text-gray-800 mb-6">
                Complete Your Payment
              </h2>

              {/* Display sponsorship package details if it's a sponsorship */}
              {donation.type === "sponsorship" &&
                donation.metadata?.packageId && (
                  <SponsorshipDetails packageId={donation.metadata.packageId} />
                )}

              <div className="bg-gray-100 p-4 rounded-lg">
                <div className="flex justify-between mb-2">
                  <span>
                    {donation.type === "sponsorship"
                      ? `${donation.metadata?.packageName} Package`
                      : "Donation to Basketball Camp"}
                  </span>
                  <span>${donation.amount.toLocaleString()}</span>
                </div>

                {donation.type === "sponsorship" && (
                  <div className="text-sm text-gray-600 mt-1 mb-2">
                    Providing {donation.metadata?.scholarships} scholarships for
                    underprivileged youth
                  </div>
                )}

                <div className="flex justify-between font-bold pt-2 border-t border-gray-300">
                  <span>Total</span>
                  <span>${donation.amount.toLocaleString()}</span>
                </div>
              </div>
            </div>

            {stripe && clientSecret ? (
              <Elements stripe={stripe}>
                <CheckoutForm
                  paymentInfo={{
                    amount: donation.amount,
                    type: donation.type,
                    clientSecret: clientSecret,
                  }}
                  setPaymentStatus={setPaymentStatus}
                  donationId={donation.id}
                />
              </Elements>
            ) : (
              <div className="text-center py-8">
                <div className="inline-block animate-spin rounded-full h-8 w-8 border-b-2 border-orange-500 mr-2"></div>
                {processing ? "Preparing Payment..." : "Loading Stripe..."}
              </div>
            )}
            {error && (
              <div className="mb-4 p-3 bg-red-100 text-red-700 rounded">
                {error}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenericCheckout;
